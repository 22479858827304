import {http} from '../../helper/commonFunc.js';

export function getTrackerConfigApi(url, actionCallback) { 
    http('get', url, {isPartnerIntegration: true}, actionCallback);
}

export function deleteTrackerConfigApi(url, actionCallback) {
    http('delete', url, {isPartnerIntegration: true}, actionCallback);
}

export function saveTrackerConfigApi(url, postData, actionCallback) {
    postData.isPartnerIntegration =  true;
    http('post', url, postData, actionCallback);
}

export function editTrackerConfigApi(url, postData, actionCallback) {
    postData.isPartnerIntegration =  true;
    http('patch', url, postData, actionCallback);
}

export function getTrackerStatusApi(url, actionCallback) { 
    http('get', url, {isPartnerIntegration: true}, actionCallback);
}

import {
    MAP_EXTERNAL_PLACEMENT, JIVOX_MACRO, DSP_MACRO, CONSTANT_VALUE, JVX_CAMPAIGNID,
    JVX_SITEID, JVX_PLACEMENTID, JVX_GROUPID, JVX_SEGNAME, JVX_UUID, JVX_IMPRESSION_ID,
    VIEWABILITY_TRACKER, THIRDPARTY_TRACKER, DSP_TAG_INTEGRATIONS
} from '../constants/trackersConstants';
import gcmImg from '../assests/gcm.png';
import moatImg from '../assests/moat.png';
import dv360Img from '../assests/dv360.png'
import doubleVerifyImg from '../assests/doubleVerify.png';
import adobeImg from '../assests/Adobe.png';
import sizmekImg from '../assests/sizmek.png';
import nielsenImg from '../assests/nielsen.png';
import c3Img from '../assests/c3.png';
import neustarImg from '../assests/neustar.png';
import mediaMathImg from '../assests/mediaMath.png';

export const Partners = {
    DOUBLE_VERIFY: "DOUBLE_VERIFY",
    MOAT: 'MOAT',
    GCM: 'GCM',
    DV360: 'DV360',
    ADOBE: 'ADOBE',
    NIELSEN: 'NIELSEN',
    SIZMEK: 'SIZMEK',
    NEUSTAR: 'NEUSTAR',
    C3: 'C3',
    MEDIAMATH: 'MEDIAMATH'
}

export const partnersInfo = {
    [Partners.ADOBE]: {
        label: 'Adobe',
        id: 'AB'
    },
    [Partners.NIELSEN]: {
        label: 'Nielsen',
        id: 'NL'
    },
    [Partners.SIZMEK]: {
        label: 'Sizmek',
        id: 'SZ'
    },
    [Partners.C3]: {
        label: 'C3',
        id: 'C3'
    },
    [Partners.NEUSTAR]: {
        label: 'Neustar',
        id: 'NS'
    },
    [Partners.MEDIAMATH]: {
        label: 'MediaMath',
        id: 'MM'
    },
    [Partners.DOUBLE_VERIFY]: {
        label: 'Double Verify',
        id: 'DV'
    },
    [Partners.GCM]: {
        label: 'GCM',
        id: 'GCM'
    },
    [Partners.DV360]: {
        label: 'DV360',
        id: 'DV360'
    },
    [Partners.MOAT]: {
        label: 'Moat',
        id: 'MOAT'
    }

}

export const urlParamsInputOptions = [
    { id: 1, name: CONSTANT_VALUE },
    { id: 2, name: MAP_EXTERNAL_PLACEMENT },
    { id: 3, name: JIVOX_MACRO }
];

export const allURLParamsInputOptions = [
    ...urlParamsInputOptions,
    { id: 4, name: DSP_MACRO }
];

export const jivoxMacro = [
    { id: 1, name: JVX_CAMPAIGNID },
    { id: 2, name: JVX_SITEID },
    { id: 3, name: JVX_PLACEMENTID },
    { id: 4, name: JVX_GROUPID },
    { id: 5, name: JVX_SEGNAME },
    { id: 6, name: JVX_UUID },
    { id: 7, name: JVX_IMPRESSION_ID }
]

export const allTrackersDetails = {
    allTrackers: [
        {
            name: VIEWABILITY_TRACKER,
            trackers: [
                {
                    pathname: "/partnerintegration/doubleVerify",
                    name: 'Double Verify',
                    cardType: Partners.DOUBLE_VERIFY,
                    trackerImg: doubleVerifyImg
                },
                {
                    pathname: "/partnerintegration/moat",
                    name: "MOAT",
                    cardType: Partners.MOAT,
                    trackerImg: moatImg
                }
            ]
        },
        {
            name: THIRDPARTY_TRACKER,
            trackers: [
                {
                    pathname: "/partnerintegration/gcm",
                    name: "Google Campaign Manager",
                    cardType: Partners.GCM,
                    trackerImg: gcmImg
                },
                {
                    pathname: "/partnerintegration/adobe",
                    name: "Adobe",
                    cardType: Partners.ADOBE,
                    trackerImg: adobeImg
                },
                {
                    pathname: "/partnerintegration/neustar",
                    name: "Neustar",
                    cardType: Partners.NEUSTAR,
                    trackerImg: neustarImg
                },
                {
                    pathname: "/partnerintegration/nielsen",
                    name: "Nielsen",
                    cardType: Partners.NIELSEN,
                    trackerImg: nielsenImg
                },
                {
                    pathname: "/partnerintegration/c3",
                    name: "C3",
                    cardType: Partners.C3,
                    trackerImg: c3Img
                },
                {
                    pathname: "/partnerintegration/sizmek",
                    name: "Sizmek",
                    cardType: Partners.SIZMEK,
                    trackerImg: sizmekImg
                },
                {
                    pathname: "/partnerintegration/mediaMath",
                    name: "MediaMath",
                    cardType: Partners.MEDIAMATH,
                    trackerImg: mediaMathImg
                },
            ]
        },
        {
            name: DSP_TAG_INTEGRATIONS,
            trackers: [
                {
                    pathname: "/partnerintegration/dv360",
                    name: "Display & Video 360",
                    cardType: Partners.DV360,
                    trackerImg: dv360Img
                }
            ]
        }
    ]
}

/* Define the schema of the table which is used to list all the configurations.
Each object in the array is mapped to a column in the table. 
Add all the columns but the last column which will contain actions(edit,delete,view)
Define those actions in the respective components */
export const DV360TableSchema = [
    {
        name: "configName",
        label: "Configuration Name",
    },
    {
        name: 'dv360AdvertiserName',
        label: "Advertiser Name"
    },
    {
        name: "dv360AdvertiserId",
        label: "Advertiser Id",
    },
    {
        name: "dv360PartnerId",
        label: "Partner Id",
    },
];

export const GCMTableSchema = [
    {
        name: 'configName',
        label: 'Configuration Name',
    },
    {
        name: 'profileId',
        label: 'Profile Id',
    },
    {
        name: 'dcmCampaign',
        label: 'GCM Campaign',
    },
    {
        name: 'trackerGenerationModel',
        label: 'Tracker Generation Model',
    },
];

export const MoatTableSchema = [
    {
        name: 'name',
        label: 'Configuration Name',
        options: {
            sort: true,
        }
    },
    {
        name: 'mediaPlan',
        label: 'Media Plan',
    },
    {
        name: 'site',
        label: 'Site',
    },
    {
        name: 'campaigns',
        label: 'Campaigns',
    }
]

export const DVTableSchema = [
    {
        name: 'name',
        label: 'Configuration Name',
    },
    {
        name: 'mediaPlan',
        label: 'Media Plan',
    },
    {
        name: 'site',
        label: 'Site'
    },
    {
        name: 'thirdPartyTrackerType',
        label: 'Tag Type'
    },
    {
        name: 'campaigns',
        label: 'Campaigns'
    }
]

export const ThirdPartyTrackersTableSchema = [
    {
        name: 'name',
        label: 'Configuration Name'
    },
    {
        name: 'mediaPlan',
        label: 'Media Plan',
    },
    {
        name: 'site',
        label: 'Site'
    },
    {
        name: 'campaigns',
        label: 'Campaigns'
    },
    {
        name: 'thirdPartyTrackerType',
        label: 'Tracker Type'
    },
]

/**
 * formActionsEnum defines the different states of a form
 */
export const formActionsEnum = {
    CREATE: 'Create',
    EDIT: 'Edit',
    VIEW: 'View'
}

/**
 * different types of payload when saving a form
 */
export const payloadType = {
    ENTIRE_FORM: "Entire Form", //Send the entire form in the request payload
    EDITED_FIELDS: "Edited Fields" //Send only edited fields in the request payload
}

/**
 * Format the configurations list obtained from the server into a data structure suitable for rendering
 * @param {Array} configList List of configurations
 * @param {Enumerator} trackerType type of the tracker
 * @returns processed configurations list
 */
export const preprocessConfigList = (configList, trackerType) => {
    if (configList.length === 0) {
        return configList;
    }
    switch (trackerType) {
        case Partners.DOUBLE_VERIFY:
        case Partners.ADOBE:
        case Partners.C3:
        case Partners.NEUSTAR:
        case Partners.NIELSEN:
        case Partners.SIZMEK:
        case Partners.MEDIAMATH:
            return configList.map(config => ({
                ...config,
                thirdPartyTrackerType: !!config.additionalParams ? config.additionalParams.thirdPartyTrackerType : '',
                // isPrimaryClickTracker will be used for configuration uniqueness validation
                isPrimaryClickTracker : !!config.additionalParams ? !!config.additionalParams.isPrimaryClickTracker : false,
                thirdPartyTrackerUrlType : !!config.additionalParams ? config.additionalParams.thirdPartyTrackerUrlType : ''
            }));
        default:
            return configList;
    }
}

import ApiConfigClass  from '../config/apiConfig';
import {INCOMING_INVITES, OUTGOING_INVITES, ALL, SAME_ORG, DIFF_ORG} from '../constants/filterConstants';
import * as appConstants from '../constants/appConstants';
import {toastr} from 'react-redux-toastr';
import {find} from 'lodash';

var axios = require('axios');
var _ = require('lodash');

export function deleteCookie(name, path, domain){
    document.cookie = name + "=;" + (path !== undefined ? "path="+path+";" : "") + (domain !== undefined ? "domain="+domain+";" : "") +"expires=Thu, 01 Jan 1970 00:00:01 GMT";
}

export function logoutResp(response) {
    deleteCookie('userid', '/', 'jivox.com');
    deleteCookie('token', '/', 'jivox.com');
    window.location = '/login.html';
}

export function httpClient(type, url, postData, actionCallback) {
    var serverAddress = !!postData.isPartnerIntegration ? ApiConfigClass.getData(appConstants.MOAT_API_ROOT): ApiConfigClass.getData(appConstants.API_ROOT);
    const client = axios.create({
        baseURL: serverAddress,
        headers: {
            'Content-Type': 'application/json',
            "Csrf-Token": "nocheck"
        },
        withCredentials: true
    });
    switch(type) {
        case 'get': 
            return client.get(url)
        case 'post':
            return client.post(url, postData)
        case 'delete':
            return client.delete(url)
        case 'put':
            return client.put(url, postData)
        case 'patch':
            return client.patch(url, postData)
        default: 
            break;
    }
}

export function http(type, url, postData, actionCallback) {
    if(postData.storePath) {
        delete postData.storePath;
    }
    httpClient(type, url, postData, actionCallback)
    .then(response => response.data)
    .then(json => typeof actionCallback !== "undefined" ? actionCallback(json): {})
    .catch(error => {
       handleError(error, postData, actionCallback)
    });
}

export function promiseCatch(error, callback) {
    handleError(error, {}, callback)
}

export function handleError(error, data, actionCallback) {
    let errorMsg = '';
    if (typeof error.response !== "undefined") {
        if(error.response.status === 401 || error.response.status === 409 ) {
            deleteCookie('userid', '/', 'jivox.com');
            deleteCookie('token', '/', 'jivox.com');
            var res = document.createElement("link");
            res.rel = "prerender";
            res.href = "../../login.html";
            document.head.appendChild(res);
            window.location = "/login.html";
        } else {
            /*
            GCM doesn't have errorMsg in the response. So, if Jivox-Apis makes a call to GCM, the response will also not have errorMsg.
            Checking for errorMsg first and then for message. 
            TODO: May have to remove errorMsg if it is not being sent in response.
            */
            errorMsg = error.response.data.errorMsg || error.response.data.message;

        }
    } else {
        // Something happened in setting up the request that triggered an Error
        errorMsg = error.message;
    }
   
    if(errorMsg) {
        actionCallback({error: errorMsg});
    }
}

export function uploadHelper(event) {
    var error = event.target.error;

    if (error != null) {
        var msg = '',
            title = 'File Upload';
        switch (error.code) {
            case error.ENCODING_ERR:
                msg = "Encoding error!";
                break;
            case error.NOT_FOUND_ERR:
                msg = "File not found!";
                break;

            case error.NOT_READABLE_ERR:
                msg = "File could not be read!";
                break;

            case error.SECURITY_ERR:
                msg = "Security issue with file!";
                break;

            default:
                msg = "Error occurred while reading the file please try again!";
        }
        
        let toastrOptions = {
            icon: 'error',
            status: 'error'
        };
        toastr.light(title, msg, toastrOptions);
        return false;
    } else {
        return true;
    }
}

export function uploadCallback(inputId, callback, response) {
    document.getElementById(inputId).value = "";
    
    var title = 'File Upload';
    let toastrOptions = {
        icon: !response.err ? 'success' : 'error',
        status: !response.err ? 'success' : 'error'
    },
    message = !response.err ? 'File uploaded temporarily. Please submit the form to save the file!' : response.msg;
    toastr.light(title, message, toastrOptions);
}

export function checkFileExtension(fileName) {
    var fileExt = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2),
    allowedType = ["PNG","JPG","JPEG","GIF","TIFF","BMP", "XML", "WOFF", "WOFF2", "TTF", "OTF", "SVG", "ZIP","ICO", 'CSS'],
    isValid = allowedType.indexOf(fileExt.toUpperCase()) >= 0;

    if(!isValid) {
        var title = 'File Upload',
            message = 'File type not supported';
        let toastrOptions = {
            icon: 'error',
            status: 'error'
        };
        toastr.light(title, message, toastrOptions);
    } 
    return isValid;
}

export function validateSize(file) {
    var FileSize = file.size / 1024 / 1024, valid = true;
    if (FileSize > 10) {
        valid = false;
        var title = 'File Upload',
            message = 'File size exceeded';
        let toastrOptions = {
            icon: 'error',
            status: 'error'
        };
        toastr.light(title, message, toastrOptions);
    } 
    return valid;
}

export function copyToClipBoard(copyText, id, successMsg) {
    var copyTextarea = document.getElementById(id);
    copyTextarea.focus();
    copyTextarea.value = copyText;
    copyTextarea.select();

    var title = 'Copy to clipboard';
    try {
        let successful = document.execCommand('copy'),
            successMsg = '';
        if(successful){
            successMsg = (!!successMsg ? successMsg : 'Content copied successfully')
        }
        
        let toastrOptions = {
            icon: successful ? 'success'  : 'error',
            status: successful ? 'success'  : 'error'
        };
        
        toastr.light(title, successMsg, toastrOptions); 
    } catch (err) {
        let toastrOptions = {
            icon: 'error',
            status: 'error'
        },
        message = 'Oops, unable to copy!';
        toastr.light(title, message, toastrOptions);
    } 
}

export function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function getOptionObject(obj, value){
    var processedData = find(obj, {id:value});
    return (!!processedData ? processedData : {});
}

export function queryStringGenerator(queryParam) {
    let query = '';
    queryParam.forEach((param, index) => {
        if(index >= 1) {
            query += '&' + param.field + '=' + param.value;
        } else {
            query += param.field + '=' + param.value;
        }
        
    })
    return query;
}

export function generateActivityMsg(activity) {
    var type = activity.action.opCode, description = activity.description, action = activity.action;
    if(action.objectType === "Entity") {
        action.objectType = "Account";
    }
    var msg = '';
    switch(type) {
        case 'ACCESS': 
            msg = `<a class="no-pointer">${description.userName}</a> accessed <a class="no-pointer">${description.objectName}</a>`;
            return msg;
        case 'CREATE':
            msg = `${action.objectType} <a class="no-pointer">${description.objectName}</a> created`;
            return msg;
        case 'UPDATE':
            msg = `${action.objectType} <a class="no-pointer">${description.objectName}</a> modified`;
            return msg;
        case 'DELETE':
            msg = `${action.objectType} <a class="no-pointer">${description.objectName}</a> deleted`;
            return msg;
        case 'UNDELETE': 
            msg = `${action.objectType} <a class="no-pointer">${description.objectName}</a> reactivated`;
            return msg;
        case 'REINVITE':
            msg = `${action.objectType} <a class="no-pointer">${description.objectName}</a> reinvited`;
            return msg;
        case 'INVITE':
            msg = `<a class="no-pointer">${description.objectName}</a> added to <a class="no-pointer">${description.accountName}</a>`;
            return msg;
        case 'SIGNIN':
            msg = `<a class="no-pointer">${description.userName}</a> has signed in to the system`;
            return msg;
        // case 'SIGNOUT':
        //     return null
        case 'PASSWORD_CHANGE':
            msg = `<a class="no-pointer">${description.userName}</a> password has been changed`;
            return msg;
        case 'FAV':
            msg = `<a class="no-pointer">${description.objectName}</a> marked as favourite by <a class="no-pointer">${description.userName}</a>`;
            return msg;
        case 'UNFAV':
            msg = `<a class="no-pointer">${description.objectName}</a> removed from favourite by <a class="no-pointer">${description.userName}</a>`;
            return msg;
        // case 'STUDIO_ACCESS':
        //     return null
        default: 
            msg = 'INVALID OPCODE';
            return msg;
    }
}

export function isValidProtocolAvailable(strVal) {
    var regEx = /^(http(s?)|ftp):\/\//i;
    return regEx.test(strVal);
}

export function getOrgURl(authorizationDetails) {
    let orgUrl = '', prefix = 'http://';

    if (authorizationDetails && authorizationDetails.organizationURL) {
        if(!isValidProtocolAvailable(authorizationDetails.organizationURL)) {
            orgUrl = prefix + authorizationDetails.organizationURL;
        } else {
            orgUrl = authorizationDetails.organizationURL;
        }
    } else {
        return '';
    }
    return orgUrl;
}

export function findItemByKey(list, searchKey, val) {
    return list.find(element => element[searchKey] === val);
}

export function findItem(list, searchItem) {
    return list.find(element => element.name === searchItem);
}

export function getOptionValue(option){
    return option.value || option.id;
};

export function getOptionLabel(option){
    return option.label || option.name || option.email;
};

/**
 * generateMenuEndPoint - return endpoint after adding pagination info and searchitem
 * @param {Object} searchMenuDetails API related info
 * @param {string} searchItem Item searched
 */
export function generateMenuEndPoint(searchMenuDetails, searchItem) {
    const pagination = !!searchMenuDetails.paginate ? searchMenuDetails.paginate : '&pageStart=1&pageEnd=20';
    const search = !!searchMenuDetails.filterQuery ? `${searchMenuDetails.filterQuery}` : `${searchMenuDetails.endPoint}`;
    searchMenuDetails.endPoint = searchItem.trim() ? `${search}${encodeURIComponent(searchItem)}` : `${searchMenuDetails.endPoint}${pagination}`;
    return searchMenuDetails;
}

/**
 * removeTrailingSlash - Return string without trailing slash
 * @param {String} site - site url
 */
export function removeTrailingSlash(site) {     
    return site.replace(/\/$/, "");
}

export function getEndPoint(filterBy) {
    let endPoint = '';
  
    switch(filterBy) {		
        case INCOMING_INVITES:
            endPoint = '/partners/partnerships?isIncoming=true&';
            //endPoint = 'http://www.mocky.io/v2/5e608429330000650097b9a2';
            break;
        case OUTGOING_INVITES:
            endPoint = '/partners/partnerships?isIncoming=false&';
            //endPoint = 'http://www.mocky.io/v2/5e608747330000690097b9b4';
            break;
        case SAME_ORG:
            endPoint = '/partners/org/partner-memberships';
            break;
        case DIFF_ORG: 
            endPoint = '/partners/persons/partner-memberships';
            break;
        default:
            endPoint = '/partners/partnerships';
            //endPoint = 'http://www.mocky.io/v2/5e6086f7330000690097b9af';
            //endPoint = 'http://www.mocky.io/v2/5e608747330000690097b9b4';
            break;
    }
    return endPoint;
}

/**
 * Scroll to a section of the page marked with ref
 * @param {Object} fieldRef 
 * @param {Object} config 
 */
export function scrollToView(fieldRef, config)  {
    if (!!fieldRef && !!fieldRef.current.scrollIntoView) {
        fieldRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest"
        });
    }
}

// use this function to log messages in console
// wrapper function for console.log() - check for debugLog setting in sessionStorage and show/supress console logs accordingly - PP-15175
export function jsLog(log){
	var debugLog = sessionStorage.getItem("debugLog");
	if(debugLog == "1"){
		console.log(log);
	}
}

export function openSupportWidget(){
    window.FreshworksWidget && window.FreshworksWidget('open');
}
import * as actionTypes from '../../constants/accountConstants.js';

export default function accountReducer(state = { 
    associatedPeople: [], combinedAcc: [], combinedCount: -1, processingInvitePerson: {processing: false, msg: ''},
    categories: [], totalCount: -1, favMarked: false, recentUsed: false, flippedcardId: '', brandData: {}, brandId: null}, action) {
    switch (action.type) {
        case actionTypes.SET_ASSOCIATED_PEOPLE:
            return {
                ...state,
                associatedPeople: action.list,
            }
        case actionTypes.SET_ALL_ACCOUNTS:
            var appendAcc;
            if(action.actionType === actionTypes.CATEGORIZED) {
                appendAcc = state.categories && state.categories.length ? [
                    ...state.categories,
                    ...action.list.categories
                ] : action.list.categories;
            } else if(action.actionType === actionTypes.RELOAD_ACCOUNTS) {
                appendAcc = action.list.categories;
            }
            return {
                ...state,
                categories: appendAcc,
                combinedAcc: [], 
                combinedCount: -1,
                totalCount: action.list.totalCount,
            }
        case actionTypes.SET_ACCOUNTS:
            var 
                identifier = action.categoryIdentifier,
                index = identifier.substring(identifier.lastIndexOf("_") + 1, identifier.length),
                appendAcc = [
                    ...state.categories[index].accounts,
                    ...action.list.account
                ];
                return { 
                    ...state, 
                    categories: state.categories.map(
                        (category, i) => i === Number(index) ? {
                            ...category, 
                            accounts: appendAcc
                        }
                        : category
                    )
                }
        case actionTypes.SET_CARD:
            if(state.combinedCount > 0) {
                if(action.showReactivate) {
                    return { 
                        ...state,
                        combinedAcc: state.combinedAcc.filter(
                            (acc, i) => acc.id !== Number(action.accId)
                        )
                    }
                } else {
                    return { 
                        ...state,
                        combinedAcc: state.combinedAcc.map(
                            (acc, i) => acc.id === Number(action.accId) ? action.cardData.account[0] : acc
                        )
                    }
                }
            } else {
                const newState = state.categories.slice();
                let accArr = newState.map((category,i) => {
                    return category.accounts.map((acc, i) => {
                        // match card id, if the response has a card then update the card
                        return acc.id === Number(action.accId) ? action.cardData.account[0] : acc
                    })
                })
                return { 
                    ...state,
                    categories: state.categories.map(
                        (category, i) =>  ({
                            ...category, 
                            accounts: accArr[i]
                        })
                    )
                }
            }
        case actionTypes.UPDATE_RECENT_ACC:
            const allCategory = state.categories.slice();
            let allAccArr = allCategory.map((category,i) => {
                return category.accounts.filter((acc, i) => {
                    if(category.category === "Recently Accessed") {
                        return acc.id !== Number(action.account.id)
                    } else {
                        return {
                            ...acc
                        }
                    }
                })
            })
            
            let recentAcc = allAccArr[1];
            recentAcc.unshift(action.account);
            return { 
                ...state, 
                recentUsed: true,
                categories: state.categories.map(
                    (category, i) => category.category === "Recently Accessed" ? {
                        ...category, 
                        accounts: recentAcc
                    }
                    :  
                    {
                        ...category,
                        accounts: allAccArr[i]
                    }
                )
            }
        case actionTypes.DROP_ACC:
            if(state.combinedCount > 0) {
                return { 
                    ...state,
                    combinedAcc: state.combinedAcc.filter(
                        (acc, i) => acc.id !== Number(action.accId)
                    )
                }
            }   
        case actionTypes.DELETE_CARD:
            if(state.combinedCount > 0) {
                return { 
                    ...state,
                    combinedAcc: state.combinedAcc.filter(
                        (acc, i) => acc.id !== Number(action.accId)
                    )
                }
            } else {
                const newState = state.categories.slice();
                let accArr = newState.map((category,i) => {
                    return category.accounts.filter((acc, i) => {
                        // match acc id and remove the deleted acc
                        return acc.id !== Number(action.accId)
                    })
                })
                return { 
                    ...state,
                    categories: state.categories.map(
                        (category, i) =>  ({
                            ...category, 
                            accounts: accArr[i]
                        })
                    )
                }
            }
        case actionTypes.ADD_FAVOURITE:
            let 
            newFavAcc = action.acc;
            newFavAcc.isFav = true;

            const newState = state.categories.slice();

            let accArr = newState.map((category,i) => {
                return category.accounts.map((acc, i) => {
                    if(acc.id === Number(newFavAcc.id)) {
                        return {
                            ...acc,
                            isFav: true
                        }
                    } else {
                        return {
                            ...acc
                        }
                    }
                })
            })

            let 
            favcategories = accArr[0];
            favcategories.unshift(newFavAcc);
            
            return { 
                ...state, 
                favMarked: true,
                categories: state.categories.map(
                    (category, i) => i === 0  ? {
                        ...category, 
                        accounts: favcategories
                    }
                    :  
                    {
                        ...category,
                        accounts: accArr[i]
                    }
                )
            }
        case actionTypes.REMOVE_FAVOURITE:
            const categories = state.categories.slice();
            let myFavAcc = categories.map((category, index) => {
                return category.accounts.filter((acc, i) => {
                    return (acc.id !== Number(action.accId) && category.category === "Favourites")
                    || category.category !== "Favourites"
                })
            })
    
            return { 
                ...state,
                categories: state.categories.map(
                    (category, i) =>  ({
                        ...category, 
                        accounts: myFavAcc[i]
                    })
                )
            }
        case actionTypes.FAVMARK_RESET:
            return {
                ...state,
                favMarked: false
            }
        case actionTypes.RECENTMARK_RESET:
            return {
                ...state,
                recentUsed: false
            }
        case actionTypes.SEARCH_RESULT_ACC:
            return {
                ...state,
                combinedAcc: [ 
                    ...action.list.account
                ], 
                combinedCount: action.list.totalCount
            }
        case actionTypes.SHOW_PERSON_INVITE_LOADER:
            return {
                ...state,
                processingInvitePerson: action.data
            }
        case actionTypes.SET_NEWACC_DATA:
            return {
                ...state,
                brands: action.newAccData[0].data.entities,
                organisation: action.newAccData[1].data.account,
            }
        case actionTypes.SET_CARD_ID:
            return {
                ...state,
                flippedcardId: action.cardId
            }
        case actionTypes.GET_ASSOCIATED_BRAND:
            let options = action.list;
            let formatBrandData = [];
            if(options.length) {
                formatBrandData = [
                    {
                        id: -1,
                        name: "Don't Use Brand Data"
                    },
                    {
                        label: "Select Brand data to use",
                        options
                    }
                ];
            } 
            return {
                ...state,
                brandData: formatBrandData,
                brandId: action.entityId
            }
        default:
            return state;
    }
}